<template>
  <Teleport to="#teleports">
    <div
      class="ui-overlay"
      :class="className"
      @mousedown.self="() => (disabledOverlay ? false : closeModal())"
    >
      <div
        :id
        ref="modalElement"
        class="c-modal"
        role="dialog"
        v-bind="$attrs"
        @keydown.tab="(event) => retrainFocus(event, modalElement)"
      >
        <slot
          v-if="isShowButtonClose"
          name="buttonClose"
        >
          <BaseButton2
            class="c-modal__button"
            :disabled="disabledButtonClose"
            @click="() => (buttonCloseEvent ? buttonCloseEvent() : closeModal())"
          >
            <BaseIcon2 id="icon-cross" />
          </BaseButton2>
        </slot>

        <slot name="content">
          <div class="c-modal__content">
            <slot />
          </div>
        </slot>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
interface Emits {
  close: [modal: string];
  open: [modal: string];
}

interface Properties {
  buttonCloseEvent?: () => void;
  class?: string;
  disabledButtonClose?: boolean;
  disabledOverlay?: boolean;
  id?: string;
  isShowButtonClose?: boolean;
}

const properties = withDefaults(defineProps<Properties>(), {
  disabledOverlay: false,
  isShowButtonClose: true,
});

const emits = defineEmits<Emits>();
const { class: className } = toRefs(properties);
const { closeModal } = useModalStore();
const { focusElement, retrainFocus } = focusInElement();
const modalElement = ref<HTMLDivElement | null>(null);

onMounted(async () => {
  emits("open", properties.id);
  focusElement(modalElement.value);

  await nextTick(() => {
    if (!modalElement.value) {
      return;
    }

    const modalContainerElement = modalElement.value.querySelector(".c-modal__container")!;

    if (!modalContainerElement) {
      return;
    }

    const contentElement = modalContainerElement.children[0] as HTMLElement;

    if (contentElement.offsetWidth > modalContainerElement.offsetWidth) {
      modalContainerElement.style.width = `${contentElement.offsetWidth}px`;
      contentElement.style.width = "auto";
    }
  });
});

onUnmounted(() => {
  emits("close", properties.id);
});

export type { Properties as BaseModalProps };
</script>

<style scoped>
.c-modal {
  position: relative;
  overflow-x: hidden;
}

.c-modal__button {
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
}
</style>
